var _container2 = jQuery("div.owl-carousel-2");

if(_container2.length > 0) {


        _container2.each(function() {
            var _this 		= jQuery(this),
                _options 	= _this.attr('data-plugin-options');

            _defaults = {
                loop: 					true,
                margin: 				10,
                nav: 					false,
                items:                  1,

                center: 				true,
                mouseDrag: 				true,
                touchDrag: 				true,
                pullDrag: 				true,
                freeDrag: 				false,
                stagePadding: 			9,
                merge: 					false,
                mergeFit: 				true,
                autoWidth: 				false,
                startPosition: 			0,
                URLhashListener: 		false,
                navRewind: 				true,
                navText: 				[
                    '<i class="fa fa-angle-left"></i>',
                    '<i class="fa fa-angle-right"></i>'
                ],
                slideBy: 				1,
                dots: 					false,
                dotsEach: 				false,
                dotData: 				false,
                lazyLoad: 				false,
                lazyContent: 			false,
                autoplay: 				true,
                autoplayTimeout: 		3000,
                autoplayHoverPause: 	true,
                smartSpeed: 			250,
                //fluidSpeed: 			'Number',
                autoplaySpeed: 			false,
                navSpeed: 				false,
                //dotsSpeed: 			'Number/Boolean',
                dragEndSpeed: 			false,
                callbacks: 				true,
                responsiveRefreshRate: 	500,
                responsiveBaseElement: 	'.item-box',
                responsiveClass: 		true,
                video: 					true,
                videoHeight: 			false,
                videoWidth: 			false,
                fallbackEasing: 		'swing',
                info: 					false,
                nestedItemSelector: 	false,
                itemElement: 			'div',
                navContainer: 			false,
                dotsContainer: 			false,

                animateOut: 			"fadeOut",
                animateIn: 				"slideInRight",

                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:2
                    },
                    1000:{
                        items:5
                    }
                }
            };


            var _config = jQuery.extend({}, _defaults, JSON.parse(_options));
            _this.owlCarousel(_config).addClass("owl-loaded");


        });



    /** Portfolio Bugfix
     *********************** **/
    if(jQuery("#portfolio").length > 0) {
        jQuery("#portfolio .item-box .owl-carousel").each(function() {

            // Fix if has owl-carousel slider!
            jQuery(this).parent().parent().find('.item-box-desc').css({"padding-top":"29px"});

        });
    }


}
